(function($) {

  /**
   * Loyalty Marketing Formatter
   */
  Drupal.behaviors.ELB_loyalty_multi_img_tout = {
    attach: function(context, settings) {

      // moving to /js/pc/shared/loyalty.js
      site.loyalty.multiImg(context);

      // var i=0;
      // var random;
      // var sequence = [];
      // var position = 0;
      // var time_per_image = $('[data-time_per_img]', context).attr('data-time_per_img');
      // while ( i < $('.loyalty_multi_image_tout_right img').size() ){
      //   random = Math.floor( Math.random() * $('.loyalty_multi_image_tout_right img', context).size() )
      //   if ( !$('.loyalty_multi_image_tout_right img', context).eq(random).hasClass('processed') ){
      //     $('.loyalty_multi_image_tout_right img', context).eq(random).addClass('processed');
      //     sequence.push(random);
      //     i++;
      //   }
      // }
      // function rotate_img() {
      //   position = (position === sequence.length-1) ? 0 : position+1;
      //   $('.loyalty_multi_image_tout_right img').addClass('hidden');
      //   $('.loyalty_multi_image_tout_right img').eq(sequence[position]).removeClass('hidden');
      //   setTimeout(rotate_img, time_per_image * 1000);
      // }
      // rotate_img();

    }
  };

})(jQuery);
